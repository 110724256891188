* {
  padding: 0;
  margin: 0;
  font-family: 'Roboto', sans-serif;
}

#root {
  min-width: 100vw;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #10E7DC;
}